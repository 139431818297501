
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "password-forgot",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      login: Yup.string()
        .email("Le format d'email n'est pas bon")
        .required("L'adresse email est requise")
        .label("Email"),
    });

    //Form submit function
    const onSubmitForgotPassword = async (values) => {
      const router = useRouter();
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      values.login = values.login.toLowerCase();
      await store.dispatch(Actions.VERIFY_USER_STATUS, {
        email: values.login,
      });

      if (store.getters.currentUser.is_active === false) {
        Swal.fire({
          title: "Votre compte n'est pas actif !",
          html:
            "Un compte à l'adresse mail <b>" +
            values.login +
            "</b> existe déjà, consultez vos mails et cliquez sur le lien d'activation. " +
            "<br><br> Pensez à vérifier vos spams ! Si vous n'avez rien reçu, cliquez sur le bouton ci-dessous et vous recevrez un nouveau lien d'activation.",
          icon: "warning",
          confirmButtonText: "J'ai compris !",
          showCancelButton: true,
          cancelButtonText: "Renvoyer le lien d'activation",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-light-secondary",
          },
        }).then((result) => {
          if (result.dismiss && result.dismiss === "cancel") {
            store
              .dispatch(Actions.RESEND_VALIDATION_EMAIL, {
                email: values.login,
              })
              .catch(() => router.push({ name: "sign-in" }));
          }
        });
      }

      if (store.getters.currentUser.is_active === true) {
        // Send login request
        await store
          .dispatch(Actions.FORGOT_PASSWORD, values)
          .then(() => {
            Swal.fire({
              title: "Le mail a bien été envoyé !",
              html:
                "Veuillez le consulter à l'adresse <b>" +
                values.login +
                "</b>, et cliquer sur le lien de réinitialisation." +
                "<br><br> Pensez à vérifier vos spams ! Si vous n'avez rien reçu au bout de quelques minutes, renouveler la procédure.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "J'ai compris !",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          })
          .catch(() => {
            router.push({ name: "password-forgot" });
          });
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});
